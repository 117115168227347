import moment from 'moment';

const currentYear = moment().format('YY');
const currentMonth = moment().format('MM');

const paymentValidator = {
  fullName: {
    presence: {
      allowEmpty: false,
      message: 'errors.fullName'
    },
    format: {
      pattern: '.*\\s.*',
      message: 'errors.fullName',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.email'
    },
    email: {
      message: 'errors.wrong_email_format'
    }
  },
  cardNumber: {
    presence: {
      allowEmpty: false,
      message: 'errors.cardNumber'
    }
  },
  expirationMonth: {
    presence: {
      allowEmpty: false,
      message: 'errors.expiration',
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 12,
      message: 'errors.expiration',
    },
    equality: {
      attribute: 'expirationYear',
      message: 'errors.expiration',
      comparator: function (expirationMonth, expirationYear) {
        return !(Number(expirationYear) === Number(currentYear) &&
          Number(expirationMonth) <= Number(currentMonth));
      },
    },
  },
  expirationYear: {
    presence: {
      allowEmpty: false,
      message: 'errors.expiration',
    },
    numericality: {
      greaterThan: Number(currentYear - 1),
      notGreaterThan: 'errors.expiration',
    },
  },
  cvc: {
    presence: {
      allowEmpty: false,
      message: 'errors.cvc',
    },
    length: {
      is: 3,
      message: 'errors.wrong_cvc_format'
    }
  },
  countryCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.countryCode'
    }
  },
  stateCode: {
    equality: {
      attribute: 'countryCode',
      message: 'errors.requiredState',
      comparator: (stateCode, countryCode) => {
        return !['US', 'CA'].includes(countryCode) ? true : !!stateCode;
      }
    }
  },
  phoneNumber: {
    format: {
      pattern: /^(\+|[0-9])[0-9]+$/,
      message: 'errors.incorrect_phone_format'
    }
  }
};

export default paymentValidator;
