import {combineReducers} from 'redux';
import messageReducer from './message/MessageReducer'
import threeDSReducer from './3DS/ThreeDSReducer'

const Reducers = combineReducers({
  messageReducer,
  threeDSReducer,
});

export default Reducers;
