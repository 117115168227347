import React, {useState} from 'react';
import XIcon from "@assets/svg/x";
import {BiChevronDown} from "react-icons/bi";
const Input = ({
                 id,
                 name,
                 label,
                 placeholder,
                 handleInputChange,
                 error,
                 onFocus,
                 value,
                 lowerCase,
                 required,
                 maxLength,
                 disabled,
                 image,
                 ...props
               }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className="relative">
      <input
        autoCapitalize={lowerCase ? 'off' : 'on'}
        id={id}
        name={name}
        value={value}
        autoComplete="off"
        onChange={e => handleInputChange(e.target.value)}
        onClick={onFocus}
        type="text"
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => setFocused(false)}
        placeholder={placeholder}
        disabled={disabled}
        className={`block px-2.5 pt-9 pb-3.5 w-full ${
          disabled ? 'bg-gray-100' : 'bg-transparent'
        } text-sm text-gray-900 rounded-lg border-1 ${
          error ? 'border border-red-500' : 'border'
        } appearance-none focus:outline-none focus:ring-0 focus:border-marketplace-blue peer h-11`}
        {...props}
      />
      <label
        htmlFor={id}
        className={`absolute text-xs duration-300 top-1.5 left-2 px-1 ${focused ? 'text-marketplace-blue' : error ? 'text-red-500' : 'text-gray-800'}`}
      >
        {label} {required && '*'}
      </label>
      {image && <div className="absolute top-2 right-3 text-gray-400 hover:text-black ">
        <img src={image} className='w-10 h-10' alt='image'/>
      </div>}
      {error && <div className={`text-xs text-red-400 pt-1`}>{error ? error.message || error : ''}</div>}
    </div>
  );
};

export default Input;


