import React from 'react';
import ReactDOM from 'react-dom';
import '@assets/css/tailwind.css';
import '@assets/css/style.css';
import App from '@core/App';
import reportWebVitals from './reportWebVitals';
import '@i18n/index';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import {Provider} from "react-redux";
import Store from "@redux/Store";

ReactDOM.render(
    <React.StrictMode>
      <Provider store={Store}>
        <App/>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
