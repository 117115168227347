let { Buffer } = require('buffer');
import {Base64} from 'js-base64';
import {cardTypes} from '@utils/data/cardTypes'

export const numbersOnly = (text) => {
  return text.replace(/[^0-9]+/g, '')
}

export const csFlexCard = async (jwk, keyId, number, expiration) => {
  const formattedMonth = numbersOnly(expiration).slice(0, 2)
  const formattedYear = `20${numbersOnly(expiration).slice(2)}`

  const encryptedCardNumber = await encryptCardNumber(number, jwk)
  const type = detectCardType(number)

  return {
    keyId,
    encryptedCardNumber,
    numericCardType: cardTypes[type],
    expirationYear: formattedYear,
    expirationMonth: formattedMonth,
  }
}

export const encryptCardNumber = async (cardNumber, jsonWebKey) => {
  const cardNumberBuffer = Buffer.from(cardNumber);

  const publicKey = await importKey(jsonWebKey, 'encrypt');
  const encryptedCardNumberBuffer = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    publicKey,
    cardNumberBuffer,
  );

  return Base64.btoa(
    String.fromCharCode.apply(null, new Uint8Array(encryptedCardNumberBuffer)),
  );
};

const importKey = async (jsonWebKey) => {

  return window.crypto.subtle.importKey(
    'jwk',
    {
      ...jsonWebKey,
      alg: 'RSA-OAEP-256',
      ext: true,
    },
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    false,
    ['encrypt'],
  );
};

const detectCardType = (number) => {
  let re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (let key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
};

export const formatAmount = (amount) => {
  return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}