import {ADD_PAYER_AUTHENTICATION, CLEAR_PAYER_AUTHENTICATION, HIDE_3DS, SHOW_3DS} from "@redux/Types";

const InitialState = {};

export default (state = InitialState, action) => {
  const {type, payerAuthentication} = action;
  switch (type) {
    case ADD_PAYER_AUTHENTICATION:
      return {...state, payerAuthentication};
    case CLEAR_PAYER_AUTHENTICATION: {
      const {payerAuthentication, ...restOfState} = state
      return restOfState;
    }
    case SHOW_3DS:
      return {...state, show: true};
    case HIDE_3DS:
      return {...state, show: false};
    default:
      return state;
  }
};
