export default {
  'paymentAt' : 'Payment at',
  'chooseMethod' : 'Choose payment method',
  'scanWithPOK' : 'Scan using POK application',
  'pay' : 'Pay',
  'pleaseWait' : 'Please wait',
  'transactionProcessing' : 'Transaction is being processed',
  'transactionProcessed' : 'Transaction completed successfully',
  'orderIsPaid': 'This order is already paid!',
  'orderIsCanceled': 'This order is canceled!',
  'orderIsOnHold': 'The payment for this order is on hold!',
  'creditCard' : 'Credit Card',
  'cardNumber' : 'Card number',
  'expiration' : 'Expiration',
  'cvc' : 'CVC',
  'email' : 'Email',
  'address' : 'Address',
  'addressPlaceholder': 'Street',
  'country' : 'Country',
  'city' : 'City',
  'zipCode' : 'ZIP Code',
  'phone' : 'Phone',
  'value' : 'Value',
  'cardCommission' : 'Card commission',
  'nameOnCard' : 'Name on card',
  'cardDetails' : 'Card Details',
  'billingAddress' : 'Billing Address',
  'searchCountry' : 'Search Country',
  'somethingWentWrong' : 'Something went wrong',
  'payment' : 'Payment',
  'openApp' : 'Open in App',
  'fillCardDetails' : 'Please make sure the card details are filled correctly',
  'checkPhoneNumberFormat' : 'Please make sure the phone number is in the correct format',
  'fillBillingDetails': 'Please make sure the billing details are filled correctly',
  'amountError': 'Invoice total cannot be less than 1',
  'paymentMethod' : 'Payment method',
  'invoiceTotal' : 'Invoice total',
  'poweredBy' : 'Powered by',
  'securePayment' : 'Secure payment',
  'state' : 'State',
  'searchState' : 'Search State',
  'contactBusiness' : 'Please contact the issuing business',
  'products' : 'Products',
  'shipping' : 'Shipping',
  'missingPOK' : "If you don't have the app, this QR Code will redirect you to the store",
  'contactingBank' : 'Connecting with the bank',
  'processingCard' : 'Processing credit / debit card',
  'product': 'Product',
  'quantity': 'Quantity',
  'price': 'Price',
  'total': 'Total',
  'cvcLength': 'CVC length can not be shorter than 3 characters',
  'cancel': 'Cancel',
  'yes': 'Yes',
  'errors': {
    'fullName': 'Name cannot be empty',
    'lastName': 'Please provide the last name',
    'email': 'Email cannot be empty',
    'wrong_email_format': 'Wrong email format',
    'cardNumber': 'Card number cannot be empty',
    'countryCode': 'Country cannot be empty',
    'requiredState': 'State cannot be empty',
    'cvc': 'CVC cannot be empty',
    'wrong_cvc_format': 'Wrong format',
    'expiration': 'Invalid expiration',
    'address': 'Address cannot be empty',
    'city': 'City cannot be empty',
    'incorrect_phone_format': 'Phone number is not in the right format',
  },
  'redirecting': 'Redirecting...',
  'ifYouHaveAMetamaskOrAdBlockerPluginItMayAffectTheProcessingOfTheCard': 'If you have have a MetaMask or AdBlocker plugin it may affect the processing of the card',
  'tryAgain': 'Try Again',
  'addBillingAddressInfo': 'Add Billing Address Info',
  'instagramBrowser': 'Instagram Browser',
  'toProceedWithPaymentPleaseOpenLinkFromAnExternalBrowser': 'To proceed with payment please open link from an external browser by clicking the three horizontal dots at the top right corner.'
}
