import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import {API_URL} from '@utils/data/API_URL';
import API from '@utils/plugins/API';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {hide3DS} from '@redux/3DS/Action';
import {hideMessage, showErrorMessage, showLoaderMessage, showSuccessMessage} from '@redux/message/Action';
import {useTranslation} from 'react-i18next';
import {THREE_DS_EVENT_ORIGIN, ORG_ID, MERCHANT_ID} from '@utils/data/3DSConstants';

const ThreeDSIFrame = ({ orderId, failRedirectUrl }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const collectionUrl = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.deviceDataCollection.url', ''));
  const [stepUpUrl, setStepUpUrl] = useState(useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.stepUp.url', '')));
  const [stepUpToken, setStepUpToken] = useState(useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.stepUp.accessToken', '')));
  const [mdId, setMdId] = useState(useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.MD', '')));
  const card = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.creditDebitCard', {}));
  const sdkOrder = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.sdkOrder', {}));
  const csAuthentication = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.csAuthentication', {}));
  const payerAuthSetupReferenceId = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.payerAuthSetupReferenceId', ''));
  const collectionToken = useSelector(state => _.get(state, 'threeDSReducer.payerAuthentication.deviceDataCollection.accessToken', ''));
  const [iframeHeight, setIframeHeight] = useState(0);

  const [sessionId, setSessionId] = useState('');

  const redirectToFailUrl = (timeout = 2000) => {
    if (failRedirectUrl) {
      setTimeout(() => {
        dispatch(showLoaderMessage('redirecting'));
        setTimeout(() => {
          window.location.assign(failRedirectUrl)
        }, timeout)
      }, timeout)
    }
  }

  useEffect(() => {
    if (!sessionId) {
      return
    }
    const socket = io(`${API_URL}/3ds-flows`, { transports: ['websocket'] });

    socket.on(`${card.id}:successful`, (res) => {
      dispatch(hide3DS())
      dispatch(showLoaderMessage())
      const d = new Date();
      const httpBrowserTimeDifference = d.getTimezoneOffset();

      API.post(`/sdk-orders/${orderId}/guest-confirm`, {
        creditCardId: card.id,
        consumerAuthenticationInformation: res.consumerAuthenticationInformation,
      }, {
        headers: {
          'cookies-accepted': navigator.cookieEnabled,
          'http-browser-color-depth': screen.colorDepth,
          'http-browser-screen-height': screen.height,
          'http-browser-screen-width': screen.width,
          'http-browser-time-difference': httpBrowserTimeDifference,
          'http-browser-java-enabled': navigator.javaEnabled(),
          'http-browser-javascript-enabled': 1,
          'fingerprint-session-id': sessionId,
          'channel-of-sale': 'WC'
        }
      }).then((response) => {
        dispatch(showSuccessMessage())
        setTimeout(() => {
          const redirectUrl = _.get(response, 'data.data.sdkOrder.redirectUrl');
          if (redirectUrl)
            window.location.assign(redirectUrl)
        }, 2000)
      }).catch(err => {
        const errorMessage = _.get(err, 'response.data.message', '');
        dispatch(showErrorMessage(errorMessage));
        redirectToFailUrl();
      })
    })
    socket.on(`${card.id}:unsuccessful`, (res) => {
      const errorMessage = _.get(res, 'errorInformation.message', '');
      if (failRedirectUrl) {
        dispatch(showErrorMessage(errorMessage));
        redirectToFailUrl();
      } else {
        dispatch(
          showErrorMessage('somethingWentWrong', errorMessage, 'tryAgain', () => {
            dispatch(hideMessage());
            dispatch(hide3DS());
          }))
      }
    })

    return () => socket.disconnect()
  }, [sessionId])

  useEffect(() => {
    let cardinalCollectionForm = document.querySelector('#cardinal_collection_form');
    if (cardinalCollectionForm) {
      dispatch(showLoaderMessage('processingCard'));
      cardinalCollectionForm.submit();
    }
    window.addEventListener('message', function (event) {
      console.log('====', event)
      if (event.origin === THREE_DS_EVENT_ORIGIN) {
        const data = JSON.parse(event.data)

        const session = data.SessionId;
        const head = document.querySelector("head");
        const script = document.createElement("script");
        if (session) {
          script.setAttribute("src", `https://h.online-metrix.net/fp/tags.js?org_id=${ORG_ID}&session_id=${MERCHANT_ID + session}`);
          head.appendChild(script);
          setSessionId(session)
        }
        setIframeHeight(event.target.innerHeight);

        const d = new Date();
        const httpBrowserTimeDifference = d.getTimezoneOffset();

        const headers = {
          'cookies-accepted': navigator.cookieEnabled,
          'http-browser-color-depth': screen.colorDepth,
          'http-browser-screen-height': screen.height,
          'http-browser-screen-width': screen.width,
          'http-browser-time-difference': httpBrowserTimeDifference,
          'http-browser-java-enabled': navigator.javaEnabled() ? 1 : 0,
          'http-browser-javascript-enabled': 1,
          'fingerprint-session-id': session,
          'channel-of-sale': 'WC'
        }

        API.post(`credit-debit-cards/${card.id}/check-3ds-enrollment`, {
          csAuthentication,
          sdkOrderId: sdkOrder.id,
          payerAuthSetupReferenceId,
        }, { headers }).then(res => {
          const { payerAuthenticationEnrollment } = res.data.data;
          if (payerAuthenticationEnrollment.status === 'PENDING_AUTHENTICATION') {
            setMdId(payerAuthenticationEnrollment.MD);
            setStepUpToken(payerAuthenticationEnrollment.stepUp.accessToken);
            setStepUpUrl(payerAuthenticationEnrollment.stepUp.url);
            let stepUpForm = document.querySelector('#step-up-form');
            if (stepUpForm) {
              dispatch(hideMessage());
              stepUpForm.submit();
            }
          } else {
            dispatch(showLoaderMessage());

            API.post(`/sdk-orders/${orderId}/guest-confirm`, {
              creditCardId: res.data.data.creditDebitCard.id,
              consumerAuthenticationInformation: res.data.data.consumerAuthenticationInformation,
            }, { headers }).then(response => {
              dispatch(showSuccessMessage());
              setTimeout(() => {
                const redirectUrl = response.data.data.sdkOrder.redirectUrl;
                if (redirectUrl) window.location.assign(redirectUrl);
              }, 2000);
            }).catch(err => {
              const errorMessage = _.get(err, 'response.data.message');
              dispatch(showErrorMessage(errorMessage));
              redirectToFailUrl()
            })
          }
        }).catch(err => {
          const errorMessage = _.get(err, 'response.data.message');
          dispatch(hide3DS());
          dispatch(showErrorMessage(errorMessage));
          redirectToFailUrl();
        })
      }
    }, { once: true });
  }, []);

  return (
    <div className="relative overflow-hidden w-full min-h-hafScreen mt-10">
      <iframe id="cardinal_collection_iframe" name="collectionIframe" height="1" width="1" className="hidden"></iframe>
      <form id="cardinal_collection_form" method="POST" target="collectionIframe" action={collectionUrl}>
        <input
          id="cardinal_collection_form_input"
          type="hidden"
          name="JWT"
          value={collectionToken}
        />
      </form>
      <iframe name="step-up-iframe" style={{ width: '100%', height: iframeHeight }}></iframe>
      {
        stepUpUrl &&
        <form id="step-up-form" target="step-up-iframe" method="post" action={stepUpUrl}>
          <input type="hidden" name="JWT" value={stepUpToken}/>
          <input type="hidden" name="MD" value={mdId}/>
        </form>
      }
    </div>
  );
};

export default ThreeDSIFrame;
