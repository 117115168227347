export default {
  'paymentAt' : "Pagamento presso",
  'chooseMethod' : "Scegli il metodo di pagamento",
  'scanWithPOK' : "Usa l'applicazione POK",
  'pay' : "Paga",
  'pleaseWait' : "Si prega di attendere",
  'transactionProcessing' : "Transazione in elaborazione",
  'transactionProcessed' : "Transaction completed successfully",
  'orderIsPaid': "L'ordine è già stato pagato!",
  'orderIsCanceled': "L'ordine è annullato",
  'orderIsOnHold': "L'ordine è in attesa",
  'creditCard' : "Carta di Credito",
  'cardNumber' : "Numero della carta",
  'expiration' : "Scadenza",
  'cvc' : "CVC",
  'email' : "Email",
  'address' : "Indirizzo",
  'addressPlaceholder': 'Strada',
  'country' : "Paese",
  'city' : "Città",
  'zipCode' : "Cap",
  'phone' : "Tel",
  'value' : "Valore",
  'cardCommission' : "Commissione",
  'nameOnCard' : "Nome sulla carta",
  'cardDetails' : "Dettagli della carta",
  'billingAddress' : "Indirizzo Di Fatturazione",
  'searchCountry' : "Cerca Paese",
  'somethingWentWrong' : "Qualcosa è andato storto",
  'payment' : "Payment",
  'openApp' : "Apri nell'app",
  'fillCardDetails' : "Per favore, si assicura che i dettagli della carta siano inseriti correttamente",
  'checkPhoneNumberFormat' : "Per favore, si assicura che il numero di telefono sia nel formato corretto",
  'fillBillingDetails': 'Per favore, si assicura che i dettagli della fatturazione siano inseriti correttamente',
  'amountError': 'Il totale della fattura non può essere meno di 1',
  'paymentMethod' : "Metodo di pagamento",
  'invoiceTotal' : "Totale fattura",
  'poweredBy' : "Powered by",
  'securePayment' : "Pagamento sicuro",
  'state' : "Stato",
  'searchState' : "Cerca Stato",
  'contactBusiness' : "Si prega di contattare l'azienda che ha preparato la fattura",
  'products' : "Prodotti",
  'shipping' : "Spedizione",
  'missingPOK' : "Se non hai l'app, questo codice QR ti reindirizzerà all'Apple Store o al Play Store di Android",
  'contactingBank' : "Collegamento con la banca ...",
  'processingCard' : "Elaborazione della carta ...",
  'product': "Prodotto",
  'quantity': "Quantità",
  'price': "Prezzo",
  'total': "Totale",
  'cvcLength': "La lunghezza del CVC non può essere meno di 3 caratteri",
  'cancel': "Annulla",
  'yes': "Si",
  'errors': {
    'fullName': "Si prega di inserire il proprio nome",
    'lastName': "Si prega di inserire il cognome",
    'email': "Si prega di inserire il email",
    'wrong_email_format': "Formato e-mail errato",
    'cardNumber': "Il numero della carta non può essere vuoto",
    'countryCode': "Il campo Paese non può essere vuoto",
    'requiredState': "Il campo stato non può essere vuoto",
    'cvc': "CVC non può essere vuoto",
    'wrong_cvc_format': "Formato errato",
    'expiration': "Scadenza non valida",
    'address': "L'indirizzo non può essere vuoto",
    'city': "La città non può essere vuota",
    'incorrect_phone_format': "Il numero di telefono non è nel formato corretto"
  },
  'redirecting': "Reindirizzamento...",
  'addBillingAddressInfo': 'Aggiungi informazioni sull\'indirizzo di fatturazione',
  "tryAgain": "Riprova",
  'ifYouHaveAMetamaskOrAdBlockerPluginItMayAffectTheProcessingOfTheCard': 'Se disponi di un plug-in MetaMask o AdBlocker, ciò potrebbe influire sull\'elaborazione della carta',
  'instagramBrowser': 'Instagram Browser',
  'toProceedWithPaymentPleaseOpenLinkFromAnExternalBrowser': 'Per procedere con il pagamento apri il link da un browser esterno cliccando sui tre puntini orizzontali in alto a destra.'
}
