import React, { useState } from 'react';
import { FaSquareCheck } from 'react-icons/fa6';
import { FaSquare } from 'react-icons/fa';

const Checkbox = ({ value, onChange, checked = false, ...props }) => {
  const [showShadow, setShowShadow] = useState(false);

  const handleCheck = () => {
    onChange(!checked);
    if (!checked) {
      setShowShadow(true);
    }
    setTimeout(() => {
      setShowShadow(false);
    }, 300);
  };

  return (
    <div {...props}>
      <div
        onClick={handleCheck}
        className={`${checked && 'border-marketplace-blue'} border hover:bg-gray-100 rounded-md tw-cursor-pointer flex items-center p-0.25`}
        style={{
          boxShadow: showShadow ? '0 0 0 7px rgba(66, 153, 225, 0.2)' : 'none',
          transition: 'box-shadow 0.3s ease',
        }}
      >
        {checked ? (
          <FaSquareCheck className="text-marketplace-blue w-5 h-5" />
        ) : (
          <FaSquare className="text-gray-100 w-5 h-5" />
        )}
      </div>
    </div>
  );
};

export default Checkbox;
