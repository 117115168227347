import Input from "@core/inputs/Input";
import React from "react";
import {useTranslation} from "react-i18next";

const BillingForm = ({
                         city,
                         setCity,
                         zip,
                         setZip,
                         address,
                         setAddress,
                         phoneNumber,
                         setPhoneNumber,
                         getError,
                         clearError
                     }) => {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col mt-5 gap-4 mb-5">
            <Input label={t('address')}
                   placeholder={t('addressPlaceholder')}
                   name="address"
                   value={address}
                   error={getError('address')}
                   onFocus={() => clearError('address')}
                   handleInputChange={setAddress}
            />
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                <Input
                    label={t('city')}
                    placeholder={t('city')}
                    name="city"
                    value={city}
                    error={getError('city')}
                    onFocus={() => clearError('city')}
                    handleInputChange={setCity}
                />
                <Input
                    label={t('zipCode')}
                    placeholder="ZIP"
                    name="zip"
                    value={zip}
                    handleInputChange={setZip}
                />
            </div>
            <div className='grid grid-cols-1 gap-5'>
                <Input label={t('phone')}
                       placeholder="+3556..."
                       name="phoneNumber"
                       value={phoneNumber}
                       error={getError('phoneNumber')}
                       onFocus={() => clearError('phoneNumber')}
                       handleInputChange={value => setPhoneNumber(value.replace(/[^0-9 +]+/g, ''))}
                />
            </div>
        </div>
    )
}
export default BillingForm;
