import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import alTranslations from "./al";
import enTranslations from "./en";
import itTranslations from "./it";

const url = new URL(window.location);
const qsLanguage = url.searchParams.get('language');

const language = ['en', 'al', 'it'].includes(qsLanguage?.toLowerCase()) ? qsLanguage.toLowerCase() : 'en';

const resources = {
  en: {
    translation: enTranslations
  },
  al: {
    translation: alTranslations
  },
  it: {
    translation: itTranslations
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
