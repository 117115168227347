import React, {useCallback, useEffect, useState} from 'react'
import Input from '@core/inputs/Input';
import Collapse from 'react-collapse';
import PrimaryButton from '@core/buttons/PrimaryButton';
import {useTranslation} from 'react-i18next';
import {csFlexCard, numbersOnly} from '@utils/helpers';
import API from '@utils/plugins/API';
import {useDispatch} from 'react-redux';
import {hideMessage, showErrorMessage, showLoaderMessage} from '@redux/message/Action';
import {addPayerAuthentication, show3DS} from '@redux/3DS/Action';
import useFormErrors from '@hooks/useFormErrors';
import paymentValidator from '@utils/validators/PaymentValidator';
import validate from "validate.js";
import _ from 'lodash'
import BillingForm from "@components/BIllingForm";
import Checkbox from "@core/inputs/Checkbox";
import countries from "@utils/data/countries";
import Select from "@core/dropdowns/Select";
import Visa from '@assets/svg/visa.svg';
import MasterCard from '@assets/svg/mastercard.svg';
import warning from '@assets/svg/warning.svg';

const PaymentForm = ({ orderId, currency, commissions, failRedirectUrl }) => {
  const [countryCode, setCountryCode] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvc, setCvc] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorLabel, setErrorLabel] = useState('');
  const [billingErrorLabel, setBillingErrorLabel] = useState('');
  const [amountErrorLabel, setAmountErrorLabel] = useState('');
  const [addBillingInfo, setAddBillingInfo] = useState(false);

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { clearError, getError, validateErrors } = useFormErrors();

  const setBillingInfo = useCallback(() => {
    const url = new URL(window.location.href);

    const firstNameQuery = url.searchParams.get('firstName');
    const lastNameQuery = url.searchParams.get('lastName');

    if (firstNameQuery && lastNameQuery) {
      setFullName(`${firstNameQuery} ${lastNameQuery}`);
    }
    setAddress(url.searchParams.get('address') || '');
    setCity(url.searchParams.get('city') || '');
    setZip(url.searchParams.get('zip') || '');
    setPhoneNumber(url.searchParams.get('phone') || '');
    setCountryCode(url.searchParams.get('country') ? url.searchParams.get('country').toUpperCase() : '');
    setStateCode(url.searchParams.get('state') ? url.searchParams.get('state').toUpperCase() : '');
    setEmail(url.searchParams.get('email') || '');
    if (address || city || zip || phoneNumber || countryCode || stateCode) {
      setAddBillingInfo(true)
    }
  }, []);

  useEffect(() => {
    setBillingInfo();
  }, [setBillingInfo]);

  const expirationFormatter = (value) => {
    if (value.length > 4) {
      return
    } else if (value.length >= 3 && value.slice(2) !== '/') {
      const month = value.slice(0, 2)
      const year = value.slice(2)
      value = month + '/' + year
      setExpirationMonth(month)
      setExpirationYear(year)
      setExpiration(value)
      return
    }
    setExpiration(value)
  }

  const threeDSTest = async (e) => {
    e.preventDefault();
    const form = document.querySelector("#sdkForm");
    const fields = validate.collectFormValues(form, { trim: true });
    fields.stateCode = stateCode || '';
    fields.countryCode = countryCode;
    fields.expirationMonth = expirationMonth;
    fields.expirationYear = expirationYear;
    if (fields.phoneNumber) {
      fields.phoneNumber = fields.phoneNumber.replace(/\s/g, '')
    } else {
      delete fields.phoneNumber;
    }
    window.scrollTo(0, 0);

    setErrorLabel('');

    setBillingErrorLabel('');
    setAmountErrorLabel('');
    const errors = validateErrors(fields, paymentValidator);
    if (errors) return

    let keyId = ''
    let jwk = ''

    try {
      const response = await API.get('/credit-debit-cards/flex-card-encryption-key')
      keyId = _.get(response, 'data.data.keyId', '')
      jwk = _.get(response, 'data.data.jwk', '')
    } catch (e) {
      return dispatch(showErrorMessage('somethingWentWrong'));
    }

    dispatch(showLoaderMessage('contactingBank'));
    let flexCard;

    try {
      flexCard = await csFlexCard(jwk, keyId, cardNumber, expiration);
    } catch (e) {
      setErrorLabel(t('fillCardDetails'));
      dispatch(hideMessage());
      return;
    }

    const name = fullName.split(/\s+/);

    const payload = {
      csFlexCard: flexCard,
      billingInfo: {
        firstName: name[0],
        lastName: name[1],
        address1: fields.address,
        locality: fields.city,
        postalCode: fields.zip,
        countryCode: fields.countryCode,
        administrativeArea: fields.stateCode,
        sameAsShipping: false,
        email: fields.email,
        phoneNumber: fields.phoneNumber,
      },
      securityCode: fields.cvc,
      csAuthentication: {
        amount: commissions.grossAmount,
        currencyCode: currency,
      },
      sdkOrder: {
        id: orderId
      }
    }

    try {
      const response = await API.post('/credit-debit-cards/guest', payload);
      const { payerAuthentication } = response.data.data;
      dispatch(addPayerAuthentication(payerAuthentication));
      dispatch(show3DS());
      dispatch(hideMessage());
    } catch (err) {
      dispatch(hideMessage());
      if (err.response && err.response.data.statusCode < 500) {
        if (err.response.data.errors.length) {
          const errorKeys = err.response.data.errors.map(error => error.key);
          if (Object.values(errorKeys).some(error => ['numericCardType', 'expirationYear', 'firstName', 'lastName', 'email', 'expirationMonth', 'securityCode'].includes(error))) {
            setErrorLabel(t('fillCardDetails'));
          }
          if (Object.values(errorKeys).some(error => ['countryCode', 'locality', 'address1'].includes(error))) {
            setBillingErrorLabel(t('fillBillingDetails'));
          } else if (Object.values(errorKeys).some(error => ['phoneNumber'].includes(error))) {
            setBillingErrorLabel(t('checkPhoneNumberFormat'));
          }
          if (Object.values(errorKeys).some(error => ['amount'].includes(error))) {
            setAmountErrorLabel(t('amountError'));
          }
          return;
        }
        setErrorLabel(err.response.data.message);
      } else {
        dispatch(showErrorMessage(err.response?.data.message));
      }
    }
  };

  const detectCardType = (cardNumber) => {
    const cleanedNumber = cardNumber.replace(/\D/g, '');

    const visaPattern = /^4/;
    const mastercardPattern = /^5[1-5]/;

    if (visaPattern.test(cleanedNumber)) {
      return Visa;
    } else if (mastercardPattern.test(cleanedNumber)) {
      return MasterCard;
    } else {
      return '';
    }
  }

  return (
    <div id="sdkForm" className="pb-2">
      <div className="flex flex-col">
        <div className="font-bold text-xl 3xl:text-2xl text-gray-800 text-left pt-5 3xl:pt-8">
          {t('cardDetails')}
        </div>
        <div className="pb-5 3xl:pb-8 flex flex-row font-inter items-center align-middle text-xxs text-gray-700">
          <img src={warning} alt='warning' className='ml-1 h-4 w-4'/>
          <span className='ml-2 lg:ml-1'>{t('ifYouHaveAMetamaskOrAdBlockerPluginItMayAffectTheProcessingOfTheCard')}</span>
        </div>
        <div className="flex flex-col gap-4">
          <Input label={t('email')}
                 placeholder='JohnDoe@mail.com'
                 name="email"
                 lowerCase
                 value={email}
                 error={getError('email')}
                 onFocus={() => clearError('email')}
                 handleInputChange={setEmail}
                 required
          />
          <Input label={t('nameOnCard')}
                 placeholder='John Doe'
                 name="fullName"
                 value={fullName}
                 error={getError('fullName')}
                 onFocus={() => clearError('fullName')}
                 handleInputChange={setFullName}
                 required
          />
          <Input label={t('cardNumber')}
                 image={detectCardType(cardNumber)}
                 placeholder="0000 00000 0000 00000"
                 name="cardNumber"
                 value={cardNumber?.match(/.{1,4}/g)?.join(' ') || ''}
                 error={getError('cardNumber')}
                 onFocus={() => clearError('cardNumber')}
                 handleInputChange={value => setCardNumber(numbersOnly(value))}
                 required
          />
          <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
            <Input label={t('expiration')}
                   placeholder="MM/YY"
                   name="expiration"
                   value={expiration}
                   error={getError('expirationMonth') || getError('expirationYear')}
                   onFocus={() => {
                     clearError('expirationMonth')
                     clearError('expirationYear')
                   }}
                   handleInputChange={value => expirationFormatter(numbersOnly(value))}
                   required
            />
            <Input label={t('cvc')}
                   placeholder="123"
                   name="cvc"
                   value={cvc}
                   error={getError('cvc')}
                   onFocus={() => {
                     clearError('cvc');
                   }}
                   handleInputChange={(value) => setCvc(numbersOnly(value))}
                   required
            />
          </div>
          <Select
            label={t('country')}
            value={countryCode}
            placeholder={t('searchCountry')}
            options={Object.values(countries)}
            optionValue={'flag'}
            optionLabel={'country'}
            error={getError('countryCode')}
            onFocus={() => {
              clearError('countryCode')
              clearError('stateCode')
            }}
            onSelect={setCountryCode}
            className="flex-1"
            required
          />
        </div>
        {errorLabel && (<div className="text-sm text-error pt-2 max-w-lg">{errorLabel}</div>)}
        <div className='cursor-pointer flex items-center pt-8 pb-3 lg:pt-5 lg:pb-0 3xl:pt-8 3xl:pb-3' onClick={() => setAddBillingInfo(!addBillingInfo)}>
          <Checkbox
            id={'billing-info'}
            checked={addBillingInfo}
            onChange={setAddBillingInfo}
          />
          <label className={`cursor-pointer ml-3 font-bold text-[15px] text-gray-800 text-left`} htmlFor={'billing-info'}>{t('addBillingAddressInfo')}</label>
        </div>
        <Collapse isOpened={addBillingInfo}>
          <BillingForm zip={zip}
                       setZip={setZip}
                       phoneNumber={phoneNumber}
                       setPhoneNumber={setPhoneNumber}
                       address={address}
                       setAddress={setAddress}
                       city={city}
                       setCity={setCity}
                       countryCode={countryCode}
                       stateCode={stateCode}
                       setStateCode={setCountryCode}
                       getError={getError}
                       clearError={clearError}/>
        </Collapse>
        {billingErrorLabel && (<div className="text-sm text-error pt-2 max-w-lg">{billingErrorLabel}</div>)}
      </div>
      {commissions && (
        <>
          {amountErrorLabel && (<div className="text-sm text-error pt-2 max-w-lg">{amountErrorLabel}</div>)}
          <div className='flex flex-end justify-end items-end'>
            <PrimaryButton
              margin={'mt-5 lg:mt-2 3xl:mt-5'}
              padding={'py-3'}
              font={'font-light tracking-wider'}
              bgColor={'bg-marketplace-blue'}
              width={'w-full lg:w-1/2'}
              primaryLabel={t('pay')}
              onClick={threeDSTest}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PaymentForm
