import {HIDE_3DS, SHOW_3DS, ADD_PAYER_AUTHENTICATION, CLEAR_PAYER_AUTHENTICATION} from "@redux/Types";


export const show3DS = () => {
  return {type: SHOW_3DS};
};

export const hide3DS = () => {
  return {type: HIDE_3DS};
};

export const addPayerAuthentication = (payerAuthentication) => {
  return {type: ADD_PAYER_AUTHENTICATION, payerAuthentication};
};

export const clearPayerAuthentication = () => {
  return {type: CLEAR_PAYER_AUTHENTICATION};
};
