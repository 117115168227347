import React, { useEffect } from 'react';

const disableScrollClass = 'scroll-lock';
const style = document.createElement('style');
style.type = 'text/css';
style.innerHTML = `
  .${disableScrollClass} {
    overflow: hidden;
  }
`;
document.head.appendChild(style);

const BlankModal = ({
                      show,
                      children,
                      title,
                      titleStyle = null,
                      icon,
                      iconBg = 'bg-emerald-100',
                      maxWidth = 'xl',
                      width = 'w-11/12 lg:w-1/2'
                    }) => {

  useEffect(() => {
    if (show) {
      document.body.classList.add(disableScrollClass);
    } else {
      document.body.classList.remove(disableScrollClass);
    }
    return () => {
      document.body.classList.remove(disableScrollClass);
    };
  }, [show]);

  return (
    <div
      className={`fixed z-40 inset-0 overflow-y-auto flex justify-center items-start lg:items-center mt-50 lg:mt-0 ${show ? '' : 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <div
        className={`bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-${maxWidth} ${width}`}>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex flex-col">
            <div className="mt-3 flex flex-col sm:flex-row text-center sm:mt-0 sm:text-left">
              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${iconBg} sm:mx-0 sm:h-10 sm:w-10`}>
                {icon}
              </div>
              <div className="mt-3 flex-1 text-center lg:text-left sm:mt-0 sm:ml-4">
                {title && (
                  <h3
                    className="text-lg mt-1.5 leading-6 font-medium text-gray-900"
                    style={titleStyle}>
                    {title}
                  </h3>
                )}
              </div>
            </div>
            <div className="mt-2 justify-center">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankModal;
