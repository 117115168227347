import React from 'react';

const PrimaryButton = ({primaryLabel, secondaryLabel, rounded, text, width, bgColor, font, padding, margin, onClick}) => {
    const roundedClass = rounded || 'rounded-6xl';
    const textClass = text || 'text-lg';
    const widthClass = width || 'w-full';
    const color = bgColor || 'bg-gradient-to-r from-gradient-light to-gradient-dark';
    const fontSize = font || 'font-bold';
    const paddingSize = padding || 'py-2';
    const marginSize = margin || 'mt-5'

    return (
        <button
          onClick={onClick}
          className={`${fontSize} ${paddingSize}
            text-white shadow-blue py-2 ${marginSize} 
            focus:outline-none active:opacity-60 ${color}
            ${roundedClass} ${textClass} ${widthClass}
          `}>
          <span className="mx-8">{primaryLabel}</span>
          <div className="text-sm font-normal mx-5">{secondaryLabel}</div>
        </button>
    )
}

export default PrimaryButton;

