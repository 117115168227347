import React, {useCallback, useEffect, useState} from 'react';
import PaymentForm from '@components/PaymentForm';
import Logo from '@assets/svg/logo.svg';
import CyberSource from '@assets/svg/cybersource.svg';
import Visa from '@assets/svg/visa.svg';
import MasterCard from '@assets/svg/mastercard.svg';
import Pci from '@assets/svg/pci.svg';
import QRPayment from "@components/QRPayment";
import TransactionAction from "@components/TransactionAction";
import {useTranslation} from "react-i18next";
import API from "@utils/plugins/API";
import ThreeDSIFrame from "@components/ThreeDSIFrame";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import warning from '@assets/svg/warning.svg';
import {
  hideMessage,
  showErrorMessage,
  showLoaderMessage,
  showSuccessMessage,
  showPendingMessage
} from "@redux/message/Action";
import io from "socket.io-client";
import {API_URL} from "@utils/data/API_URL";
import {isIOS, isMobile, isAndroid, isMobileSafari} from "react-device-detect";
import LanguageSwitch from "@components/LanguageSwitch";
import PaymentMethodsTabs from "@components/payment/PaymentMethodsTabs";
import OrderDetails from "@components/OrderDetails";
import BlankModal from "@core/modal/BlankModal";
const App = () => {
  const [method, setMethod] = useState('credit');
  const [commissions, setCommissions] = useState(undefined);
  const [sdkOrder, setSdkOrder] = useState(undefined);
  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState([]);
  const [instagramBrowser, setInstagramBrowser] = useState(false);

  const showMessage = useSelector(state => _.get(state, 'messageReducer.show', false));
  const show3DS = useSelector(state => _.get(state, 'threeDSReducer.show', false));

  const urlObject = new URL(window.location.href);
  const split = urlObject.pathname.split('/');
  const orderId = split[split.length - 1];

  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(`${API_URL}/sdk-orders`, {transports: ['websocket']});

    socket.on(`${orderId}:order-confirmed`, (res) => {
      dispatch(showSuccessMessage())
      socket.disconnect()
      const {redirectUrl} = res.sdkOrder
      if (redirectUrl) {
        setTimeout(() => {
          window.location.assign(redirectUrl);
        }, 2000);
      }
    })

    return () => socket.disconnect()
  }, [orderId]);

  const deepLink = useCallback(() => {
    if (isMobile) {
      try {
        window.location.assign(`pokpay://sdk-orders/${orderId}`);
      } catch (e) {
        console.log('No app found');
      }
      setTimeout(() => {
        if (document.hasFocus()) {
          if (isMobileSafari) {
            window.location.replace("https://www.pokpay.io");
          } else if (isAndroid) {
            window.location = "https://play.google.com/store/apps/details?id=io.pokpay";
          } else if (isIOS) {
            window.location = "https://apps.apple.com/app/id1590835547";
          } else {
            window.location.replace("https://www.pokpay.io");
          }
        }
      }, 1000);
    } else {
      window.location.replace("https://www.pokpay.io");
    }
  }, [orderId])

  useEffect(() => {
    if (navigator.userAgent.includes("Instagram")) {
      setInstagramBrowser(true);
    }
    dispatch(showLoaderMessage('pleaseWait'));
    if (split.includes('sdk-orders') && orderId) {
      API.get(`/sdk-orders/${orderId}`)
        .then(response => {
          const {sdkOrder, commissions} = response.data.data
          const {isCompleted, isCanceled, canBeCaptured, redirectUrl, supportedPaymentMethods} = sdkOrder
          setSdkOrder(sdkOrder)
          setCommissions(commissions)
          if (Array.isArray(supportedPaymentMethods) && supportedPaymentMethods.length > 1) {
            const paymentMethod = supportedPaymentMethods.find(pm => pm === 'credit-debit-card') || supportedPaymentMethods[0];
            setMethod(paymentMethod)
          } else {
            setMethod(supportedPaymentMethods[0])
          }
          setSupportedPaymentMethods(supportedPaymentMethods)
          if (isCanceled) {
            dispatch(showErrorMessage('orderIsCanceled'))
            return;
          }
          if (canBeCaptured) {
            dispatch(showPendingMessage('orderIsOnHold'))
            return;
          }
          if (isCompleted) {
            redirectUrl ? window.location.assign(redirectUrl) : dispatch(showSuccessMessage('orderIsPaid'))
            return;
          }
          dispatch(hideMessage())
        })
        .catch(err => {
          dispatch(showErrorMessage(err.response?.data.message))
        })
    } else {
      window.location.assign('https://www.pokpay.io')
    }
  }, [])

  return (
    <>
      {isMobile && (
        <div className="flex bg-gray-200 justify-between px-5 py-2">
          <div className='flex'>
            <img src={Logo} height={50} width={50} alt=""/>
            <div>
              <div className='flex flex-col ml-2'>
                <div>POK</div>
                <div className='text-gray-500 text-sm'>Open in POK app</div>
              </div>
            </div>
          </div>
          <div className={'flex items-center'}>
            <button
              className='cursor-pointer px-3 py-1 text-right text-white bg-blue-600 rounded-full'
              onClick={deepLink}>Open
            </button>
          </div>
        </div>
      )}
      <div className="flex lg:flex-row flex-col-reverse min-h-screen lg:h-screen bg-white">
        <OrderDetails sdkOrder={sdkOrder}/>
        <div
          className="bg-white lg:overflow-y-auto flex flex-col justify-center lg:w-1/2 w-full px-5 md:px-24 lg:px-15">
          <div className="lg:min-h-screen w-full max-w-4xl mx-auto px-2 xl:px-12 pt-8 3xl:pt-12 3xl:pb-12">
            <div className={!showMessage ? 'hidden' : 'my-auto flex flex-col justify-center h-full'}>
              <TransactionAction/>
            </div>
            <div className={showMessage ? 'hidden' : 'flex flex-col h-full justify-between'}>
              {show3DS ? <ThreeDSIFrame orderId={orderId} failRedirectUrl={sdkOrder?.failRedirectUrl}/> : (
                <>
                  <div
                    className="flex lg:hidden 3xl:flex lg:text-2xl text-xl font-bold pt-1 pb-10 flex-row justify-end">
                    <LanguageSwitch/>
                  </div>
                  <div>
                    {supportedPaymentMethods.length > 1 && (
                      <PaymentMethodsTabs
                        selectedMethod={method}
                        onSelection={setMethod}
                        supportedMethods={supportedPaymentMethods}
                      />
                    )}
                    <div className={method !== 'credit-debit-card' ? 'hidden' : undefined}>
                      <PaymentForm currency={sdkOrder?.currencyCode} commissions={commissions} orderId={orderId}
                                   failRedirectUrl={sdkOrder?.failRedirectUrl}/>
                    </div>
                    <div className={method !== 'rpay-credit' ? 'hidden' : undefined}>
                      <QRPayment orderId={orderId}/>
                    </div>
                  </div>
                  <div
                    className={`flex ${method !== 'credit-debit-card' ? 'flex-col' : 'flex-col lg:flex-row'} items-center justify-center align-middle mx-auto pb-10 lg:pb-0 gap-3`}>
                    <div className="flex flex-row justify-center py-5">
                      <img src={Visa} alt="Visa" className='w-14 h-9'/>
                      <img src={MasterCard} alt="MasterCard" className='w-14 h-9 mx-3'/>
                      <img src={Pci} alt="PCI" className='h-9'/>
                    </div>
                    <div className="flex flex-row justify-center items-center text-brand-gray text-sm">
                      <img src={CyberSource} alt="CyberSource" className="h-9 mr-3"/>
                      {t('poweredBy')} CyberSource
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {instagramBrowser &&
        <BlankModal
          show={instagramBrowser}
          iconBg={'bg-yellow-100'}
          icon={<img src={warning} alt={'Pok'}/>}
          title={t('instagramBrowser')}
        >
          <div className='text-gray-700 p-2 font-light tracking-wide text-center lg:text-left'>{t('toProceedWithPaymentPleaseOpenLinkFromAnExternalBrowser')}</div>
        </BlankModal>
      }
    </>
  );
};

export default App;
