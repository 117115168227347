import React from 'react';
import Flags from '@assets/images/flags';
import {useTranslation} from 'react-i18next';

const LanguageSwitch = () => {
  const {i18n} = useTranslation()

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <div className="flex flex-row max-h-7 align-middle">
      <button
        className={`px-2 ${i18n.language === 'al' ? 'bg-brand-light-blue-50 border-brand-light-blue-50' : 'border-r-0'} border rounded-md rounded-r-none focus:outline-none`}
        onClick={() => switchLanguage('al')}
      >
        <img className="h-6" src={Flags.AL.default} />
      </button>
      <button
        className={`px-2 ${i18n.language === 'en' ? 'bg-brand-light-blue-50 border-brand-light-blue-50' : 'border-l-0'} border focus:outline-none`}
        onClick={() => switchLanguage('en')}
      >
        <img className="h-6" src={Flags.GB.default} />
      </button>
      <button
        className={`px-2 ${i18n.language === 'it' ? 'bg-brand-light-blue-50 border-brand-light-blue-50' : 'border-l-0'} border rounded-md rounded-l-none focus:outline-none`}
        onClick={() => switchLanguage('it')}
      >
        <img className="h-6" src={Flags.IT.default} />
      </button>
    </div>
  )
}

export default LanguageSwitch;
