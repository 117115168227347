export default {
  'paymentAt' : 'Paguaj në',
  'chooseMethod' : 'Zgjidh një nga menyrat e mëposhtme të pagesës',
  'scanWithPOK' : 'Skano duke përdorur aplikacionin POK',
  'pay' : 'Paguaj',
  'pleaseWait' : 'Ju lutem prisni',
  'transactionProcessing' : 'Transaksioni po kryhet',
  'transactionProcessed' : 'Transaksioni u krye me sukses',
  'orderIsPaid': 'Porosia është paguar!',
  'orderIsCanceled': 'Porosia është anuluar!',
  'orderIsOnHold': 'Pagesa për këtë porosi është në pritje për tu konfirmuar!',
  'creditCard' : 'Kartë Krediti',
  'cardNumber' : 'Numri i kartës',
  'expiration' : 'Skadenca',
  'cvc' : 'CVC',
  'email' : 'Email',
  'address' : 'Adresa',
  'addressPlaceholder': 'Rruga',
  'country' : 'Shteti',
  'city' : 'Qyteti',
  'zipCode' : 'Kodi ZIP',
  'phone' : 'Telefon',
  'value' : 'Vlera',
  'cardCommission' : 'Komision karte',
  'nameOnCard' : 'Emri në kartë',
  'cardDetails' : 'Detajet e kartës',
  'billingAddress' : 'Adresa e faturimit',
  'searchCountry' : 'Kërko Shtetin',
  'somethingWentWrong' : 'Ndodhi një gabim',
  'payment' : 'Pagesë',
  'openApp' : 'Hap aplikacionin',
  'fillCardDetails' : 'Ju lutem sigurohuni që fushat e kartës janë mbushur saktësisht',
  'checkPhoneNumberFormat' : 'Ju lutem sigurohuni që numri i celularit është në formatin e duhur',
  'fillBillingDetails': 'Ju lutem sigurohuni që fushat e adresës janë mbushur saktësisht',
  'amountError': 'Vlera e faturës nuk mund të jetë më e vogël se 1',
  'paymentMethod' : 'Mënyra e pagesës',
  'invoiceTotal' : 'Totali i faturës',
  'poweredBy' : 'Mundësuar nga',
  'securePayment' : 'Pagesë e sigurt',
  'state' : 'Rajoni',
  'searchState' : 'Kërko Rajonin',
  'contactBusiness' : 'Ju lutemi kontaktoni biznesin',
  'products' : 'Produktet',
  'shipping' : 'Transporti',
  'missingPOK' : "Nëse nuk keni aplikacionin e instaluar ky QR Code do t'ju drejtojë për ta instaluar atë",
  'contactingBank' : 'Duke u lidhur me bankën',
  'processingCard' : 'Duke procesuar kartën e kreditit / debitit',
  'product': 'Produkti',
  'quantity': 'Sasia',
  'price': 'Çmimi',
  'total': 'Totali',
  'cvcLength': 'Kodi CVC nuk mund të jetë më pak se 3 numra',
  'cancel': 'Mbyll',
  'yes': 'Po',
  'errors': {
    'fullName': 'Emri nuk mund të jetë bosh',
    'lastName': 'Mbiemri nuk mund të jetë bosh',
    'email': 'Email nuk mund të jetë bosh',
    'wrong_email_format': 'Format i gabuar email-i',
    'cardNumber': 'Vendosni numrin e kartës',
    'countryCode': 'Shteti nuk mund të jetë bosh',
    'requiredState': 'Rajoni nuk mund të jetë bosh',
    'cvc': 'Vendosni CVC',
    'wrong_cvc_format': 'Format i gabuar',
    'expiration': 'Invalid expiration',
    'address': 'Vendosni adresën',
    'city': 'Vendosni qytetin',
    'incorrect_phone_format': 'Numri nuk është në formatin e duhur',
  },
  'redirecting': 'Redirecting ...',
  'ifYouHaveAMetamaskOrAdBlockerPluginItMayAffectTheProcessingOfTheCard': 'Nëse keni një plugin MetaMask ose AdBlocker, kjo mund të ndikojë në përpunimin e kartës',
  'tryAgain': 'Provo përsëri',
  'addBillingAddressInfo': 'Shto informacion për adresën e faturimit',
  'instagramBrowser': 'Instagram Browser',
  'toProceedWithPaymentPleaseOpenLinkFromAnExternalBrowser': 'Për të vazhduar me pagesën, ju lutemi hapni lidhjen nga një shfletues i jashtëm duke klikuar tre pikat horizontale në këndin e sipërm djathtas.'
}
