import {HIDE_MESSAGE, SHOW_MESSAGE} from "@redux/Types";
import * as Loader from '@assets/animations/loader.json'
import * as Success from '@assets/animations/success.json'
import * as Failure from '@assets/animations/failure.json'
import * as Pending from '@assets/animations/pending.json'

export const showLoaderMessage = (loadingMessage = 'transactionProcessing', secondaryMessage = 'pleaseWait') => {
  const payload = {
    messageType: 'loading',
    message: loadingMessage,
    secondaryMessage,
    animationData: Loader
  }
  return {type: SHOW_MESSAGE, payload};
};

export const showSuccessMessage = (successMessage = 'transactionProcessed', secondaryMessage) => {
  const payload = {
    messageType: 'success',
    message: successMessage,
    secondaryMessage,
    animationData: Success
  }
  return {type: SHOW_MESSAGE, payload };
};

export const showPendingMessage = (pendingMessage = '', secondaryMessage) => {
  const payload = {
    messageType: 'pending',
    message: pendingMessage,
    secondaryMessage,
    animationData: Pending
  }
  return {type: SHOW_MESSAGE, payload };
};

export const showErrorMessage = (errorMessage = 'somethingWentWrong', secondaryMessage, buttonLabel, buttonAction) => {
  const payload = {
    messageType: 'error',
    message: errorMessage,
    secondaryMessage,
    animationData: Failure,
    buttonLabel,
    buttonAction
  }
  return {type: SHOW_MESSAGE, payload};
};

export const hideMessage = () => {
  return {type: HIDE_MESSAGE};
};
