import React from 'react'
import {formatAmount} from "@utils/helpers";
import {useTranslation} from "react-i18next";
import LanguageSwitch from "@components/LanguageSwitch";

const OrderDetails = ({ sdkOrder }) => {
  const { t } = useTranslation()

  return <div className="z-40 bg-white lg:overflow-y-auto lg:w-1/3 2xl:px-16 w-full py-12 px-7 flex-grow flex flex-col justify-between shadow-outer-top-light lg:shadow-inner-light">
    <div className='flex flex-row justify-between items-center align-middle pt-4 lg:pt-10'>
      <div className='flex flex-col'>
        <div className='flex justify-start items-center align-middle'>
          {sdkOrder?.merchant.logoUrl && (
            <img src={sdkOrder?.merchant.logoUrl} alt="" className="border border-gray-700 p-0.5 h-8 w-8 rounded-full object-contain object-left"/>
          )}
          {sdkOrder?.merchant.tradeName && <div title={sdkOrder?.merchant.tradeName}
               className="ml-2 text-xl font-normal text-gray-600 opacity-80 overflow-hidden overflow-ellipsis">
            {t('pay')} <span className='uppercase'>{sdkOrder?.merchant.tradeName}</span>
          </div>}
        </div>
        {sdkOrder && <div
          className="pt-2 text-4xl font-semibold">{formatAmount(sdkOrder?.finalAmount)} {sdkOrder.currencyCode}</div>}
      </div>
      <div className="hidden lg:flex 3xl:hidden lg:text-2xl text-xl font-bold pt-1 pb-10 flex-row justify-end">
        <LanguageSwitch/>
      </div>
    </div>
    <div>
      {sdkOrder ? (
        <div className="my-5">
          <div title={sdkOrder.description} className="text-2xl font-semibold overflow-hidden overflow-ellipsis">
            {sdkOrder.description}
          </div>
          <div className="mt-2">
            {sdkOrder.products.map(({ name, quantity, price }) => (
              <div key={`${name}-${price}-${quantity}`}
                   className="flex p-2 text-sm text-gray-700">
                <div className='flex-1 text-gray-800 font-light'>{name}</div>
                <div className="flex flex-1 text-gray-800 font-light justify-center">{quantity} {t('pc')}</div>
                <div className="hidden lg:flex flex-1 text-gray-800 font-light justify-center">{formatAmount(price)} {sdkOrder.currencyCode}</div>
                <div
                  className="flex flex-1 justify-end text-gray-800 font-semibold">{formatAmount(quantity * price)} {sdkOrder.currencyCode}</div>
              </div>
            ))}
          </div>
          <hr className="px-5 my-2"/>
          <div className='flex flex-col my-4 mx-2 text-base'>
            <div
              className="flex flex-row justify-between align-middle font-inter leading-7 items-center">
              <div className='text-gray-800 font-light'>{t('total')}</div>
              <div className='text-gray-800 font-semibold'>{formatAmount(sdkOrder.amount)} {sdkOrder.currencyCode}</div>
            </div>
            <div
              className="flex flex-row justify-between align-middle font-inter leading-7 items-center py-2">
              <div className='text-gray-800 font-light'>{t('shipping')}</div>
              <div className='text-gray-800 font-semibold'>{formatAmount(sdkOrder.shippingCost)} {sdkOrder.currencyCode}</div>
            </div>
            <hr className="px-5 my-2"/>
            <div
              className="flex flex-row justify-between align-middle font-inter leading-7 items-center">
              <div className='text-gray-800 font-light'>{t('invoiceTotal')}</div>
              <div className='text-gray-800 font-semibold'>{formatAmount(sdkOrder.finalAmount)} {sdkOrder.currencyCode}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-xl font-semibold text-brand-gray">{t('contactBusiness')}</div>
      )}
    </div>
    <div/>
  </div>
}

export default OrderDetails
