import React, {useCallback} from 'react'
import QRCode from 'qrcode.react'
import {useTranslation} from "react-i18next";
import {isAndroid, isIOS, isMobile, isMobileSafari} from "react-device-detect";

const QRPayment = () => {
  const {t} = useTranslation();

  const url = new URL(window.location.href);
  const qrValue = `${url.origin}${url.pathname}`;

  const sdkOrderId = url.pathname.split('/')[2];

  const handleDeepLinking = useCallback(() => {
    if (isMobile) {
      try {
        window.location.assign(`pokpay://sdk-orders/${sdkOrderId}`);
      } catch (e) {
        console.log('No app found');
      }
      setTimeout(() => {
        if (document.hasFocus()) {
          if (isMobileSafari) {
            window.location.replace("https://www.pokpay.io");
          } else if (isAndroid) {
            window.location = "https://play.google.com/store/apps/details?id=io.pokpay";
          } else if (isIOS) {
            window.location = "https://apps.apple.com/app/id1590835547";
          } else {
            window.location.replace("https://www.pokpay.io");
          }
        }
      }, 1000);
    } else {
      window.location.replace("https://www.pokpay.io");
    }
  }, [sdkOrderId])

  return (
    <div className="flex flex-col items-center py-8">
      <div className="font-bold text-2xl text-gray-800 text-center pt-4 pb-2 lg:mx-16">
        {t('scanWithPOK')}
      </div>
      <div className="text-gray-700 opacity-90 font-light font-inter mb-7 text-center">{t('missingPOK')}</div>
      <div className="p-4 border shadow-xl rounded-2xl max-w-qr">
        <div className="bg-white p-4 rounded-lg flex justify-center">
          <QRCode
            value={qrValue}
            size={175}
            level="L"
          />
        </div>
      </div>
      {isMobile && (
        <span className="text-brand-light-blue font-light underline py-7 cursor-pointer" onClick={handleDeepLinking}>
          {t('openApp')}
        </span>
      )}
    </div>
  )
}

export default QRPayment
