import React from 'react'
import Logo from "@assets/svg/logo.svg";
import CreditCard from "@assets/svg/creditCardOption.svg";
import {useTranslation} from "react-i18next";

const methods = [
  {
    key: 'rpay-credit',
    icon: <img src={Logo} height={52} width={52} className={'my-auto'} alt=""/>
  },
  {
    key: 'credit-debit-card',
    icon: <img src={CreditCard} height={24} width={24} alt=""/>,
    text: 'creditCard'
  }
]

const PaymentMethodsTabs = ({ selectedMethod, onSelection }) => {
  const { t } = useTranslation();

  return (<div className="bg-dark-gray-bg rounded-md mx-auto flex flex-row p-0.5">
    {methods.map(({ key, icon, text }) => (
      <div
        key={key}
        className={`flex w-full p-1.5 justify-center items-center align-middle ${selectedMethod === key && 'bg-white shadow'} rounded-md cursor-pointer flex-row`}
        onClick={() => onSelection(key)}
      >
        {icon}
        {text && (<div
          className="hidden lg:flex ml-2 font-semibold text-center mt-1 mx-5">{t(text)}</div>)}
      </div>
    ))}
  </div>)
}

export default PaymentMethodsTabs
