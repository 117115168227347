import React, {useEffect, useRef, useState} from 'react';
import XIcon from "@assets/svg/x";
import _ from "lodash";
import {BiChevronDown} from "react-icons/bi";

const Select = (
  {
    id,
    value,
    label,
    placeholder,
    options = [],
    optionValue = 'value',
    optionLabel = 'label',
    onSelect,
    onFocus,
    error,
    disabled,
    required,
    className,
  }) => {
  const [qs, setQs] = useState('');
  const placeholderOption = {[optionValue]: null, [optionLabel]: ''};
  const [selected, setSelected] = useState(placeholderOption);
  const [displayOptions, setDisplayOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setDisplayOptions(options)
    if (!options.includes(selected)) {
      setSelected(placeholderOption)
    }
  }, [options])

  const searchRef = useRef();

  const clearOption = () => {
    setQs('');
    setSelected(placeholderOption);
  }

  const optionsArray = () => {
    return displayOptions.map(option => {
      return <div
        title={option[optionLabel]}
        key={option[optionLabel]}
        className={`py-1 px-3 flex text-gray-800 font-inter font-light flex-row items-center cursor-pointer ${option[optionValue] === selected[optionValue] ? 'bg-brand-light-blue text-white hover:bg-brand-light-blue' : 'hover:bg-brand-light-blue-10'}`}
        onClick={() => {
          handleChange(option[optionLabel])
        }}
      >
        {option[optionLabel]}
      </div>
    })
  };

  const handleChange = (value) => {
    if (disabled || !options.length) {
      return;
    }
    setQs(value);

    const matchingOption = options.find(option => option[optionLabel].toLowerCase() === value.toLowerCase());
    if (matchingOption) {
      setSelected(matchingOption);
      setQs(matchingOption[optionLabel]);
      setDisplayOptions(options);
      return;
    }
    search(value);
    setSelected({[optionValue]: null, [optionLabel]: value});
  };

  const handleFocus = () => {
    if (disabled) {
      searchRef.current.blur()
      return;
    }
    setShowOptions(true);
  }

  const search = _.debounce(val => {
    const filteredOptions = options.filter(option => option[optionLabel].toLowerCase().includes(qs.toLowerCase()));
    setDisplayOptions(filteredOptions);
  }, 300);

  useEffect(() => {
    onSelect(selected[optionValue]);
  }, [selected]);

  const addOnClickOutsideListener = () => {
    document.addEventListener("click", onClickOutsideListener);
  }

  const onClickOutsideListener = () => {
    setShowOptions(false)
    document.removeEventListener("click", onClickOutsideListener);
  }

  useEffect(() => {
    if (value && options.length) {
      const initialOption = options.find(option => option[optionValue] === value);
      setSelected(initialOption || placeholderOption);
      setQs(initialOption[optionLabel] || '');
    }
  }, [value, options.length]);

  return (
    <div className={`relative ${className}`} onBlur={() => addOnClickOutsideListener()}>
      <input
        onClick={onFocus}
        id={id}
        ref={searchRef}
        value={selected[optionLabel]}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={disabled}
        className={`block px-2.5 pt-9 pb-4  w-full ${
          disabled ? 'bg-gray-100' : 'bg-transparent'
        } text-sm text-gray-900 rounded-lg border-1 ${
          error ? 'border border-red-500' : 'border'
        } appearance-none focus:outline-none focus:ring-0 focus:border-marketplace-blue peer h-12`}
      />
      <label
        htmlFor={id}
        className={`${error && 'text-red-500'} absolute text-xs duration-300 top-1.5 left-2 px-1`}
      >
        {label} {required && '*'}
      </label>
      <div className="absolute top-5 right-3 text-gray-400 hover:text-black cursor-pointer" onClick={clearOption}>
        {(qs || selected?.optionLabel) ? <XIcon/> : <BiChevronDown className='w-6 h-6'/>}
      </div>
      <div
        className={`${!showOptions ? 'hidden' : ''} w-full mt-1 border-1 absolute
          overflow-y-auto overflow-ellipsis flex flex-col overflow-hidden
          z-50 bg-white max-h-56 rounded-md shadow-2xl`}>
        {optionsArray()}
      </div>
      {error && <div className={`text-xs text-red-400 pt-1`}>{error ? error.message || error : ''}</div>}
    </div>
  )
}

export default Select;

