import {SHOW_MESSAGE, HIDE_MESSAGE} from "@redux/Types";

const InitialState = {};

export default (state = InitialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SHOW_MESSAGE:
      return {...InitialState, show: true, payload};
    case HIDE_MESSAGE:
      return InitialState;
    default:
      return state;
  }
};
