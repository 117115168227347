import React from 'react'
import Lottie from 'react-lottie'
import {useTranslation} from 'react-i18next';
import PrimaryButton from '@core/buttons/PrimaryButton';
import {useSelector} from 'react-redux';
import _ from 'lodash';

const TransactionAction = () => {
  const { t } = useTranslation()

  const {
    messageType,
    message,
    secondaryMessage,
    animationData,
    buttonLabel,
    buttonAction
  } = useSelector(state => _.get(state, 'messageReducer.payload', {}));

  return (
    <div className={`${messageType === 'success' ? 'py-36' : 'py-40'} flex flex-col justify-center`}>
      <Lottie
        options={{
          loop: messageType === 'loading',
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={75}
        width={75}
      />
      <div className="text-2xl font-bold text-center pt-4">
        {t(message)}
      </div>
      <div className="text-grey-text text-sm text-center pt-1 pb-5">
        {t(secondaryMessage)}
      </div>
      <div className="px-28">
        {buttonAction &&
          <div className="flex justify-center">
            <PrimaryButton primaryLabel={t(buttonLabel)} onClick={buttonAction} width="w-1/3"/>
          </div>
        }
      </div>
    </div>
  )
}

export default TransactionAction
